<template>
    <div class="painting">
        <Header :baseInfo="baseInfo"/>
        <div class="p_banner">
            <img :src="baseImgUrl + 'painting_10.png'" alt="" />
        </div>

        <div class="p_description">
            <div class="content">
                <div class="item">
                    <img :src="baseImgUrl + 'painting_1.png'" alt="" />
                </div>
                <div class="txt">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;六品国画是六品书院旗下面向 6-16
                    岁青少年儿童的国画教育品牌，遵循“书画同源”的基本理论，将炉火纯青的书法艺术技巧修炼和研究融入到国画的学习课程
                    之中，书画并进，大有裨益。<br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;依据自然周期规律、心理周期规律、潜能开发规律及知识学习规律，以少儿的喜好为宗旨，循序渐进地打造了完整的课程体系，激发少儿兴趣，锻炼美学能力。
                </div>
            </div>
        </div>

        <div class="p_line_img">
            <img :src="baseImgUrl + 'painting_19.png'" alt="" />
        </div>

        <div
            class="p_course"
            :style="`background:url(${baseImgUrl}painting_20.png);background-size:contain;`"
        >
            <div class="tilte">
                <img :src="baseImgUrl + 'painting_11.png'" alt="" />
                <h5>学院派正统体系+当代创新教育</h5>
            </div>
            <div class="content">
                <div class="item">
                    <div class="item_l">
                        <h2>美院国画大师 孙文文</h2>
                        <p>
                            视频教学讲解专业知识点，并
                            进行绘画示范，内容通俗易懂，兼具趣味性与专业性，课堂学
                            习效果佳；名师视频任意反复<br/>观看，深刻掌握绘画细节。
                        </p>
                        <img :src="baseImgUrl + 'painting_21.png'" alt="" class="name" />
                    </div>
                    <div class="item_r">
                        <img :src="baseImgUrl + 'painting_3.png'" alt="" />
                    </div>
                </div>
                <div class="item">
                    <div class="item_l">
                        <h2>机构自己老师</h2>
                        <p>
                            手把手进行练习指导，反馈学 生课堂表现和学习效果，课后
                            作业点评等教学服务，保证科 学严谨的教学流程和规范专业 的课程体系。
                        </p>
                        <img :src="baseImgUrl + 'painting_22.png'" alt="" class="name" />
                    </div>
                    <div class="item_r">
                        <img :src="baseImgUrl + 'painting_2.png'" alt="" />
                    </div>
                </div>
            </div>
        </div>

        <div class="p_campus">
            <div class="tilte">
                <img :src="baseImgUrl + 'painting_12.png'" alt="" />
            </div>
            <div class="content">
                <div class="item">
                    <img :src="baseImgUrl + 'painting_17.png'" alt="" class="icon" />
                    <div class="cell">
                        <div class="name">美院大师领衔资深教研团队</div>
                        <div class="text">
                            美院大师孙文文老师领衔教学，资深教研团队由全国多个艺术<br/>院校的国画教育研究学者构成
                        </div>
                    </div>
                </div>
                <div class="item">
                    <img :src="baseImgUrl + 'painting_16.png'" alt="" class="icon" />
                    <div class="cell">
                        <div class="name">标准化课程包 提升教学实效</div>
                        <div class="text">
                            标准化的PPT/教案设计/材料，帮助校区老师实现规范教学过程和细节管理，大大提高教学效率
                        </div>
                    </div>
                </div>
                <div class="item">
                    <img :src="baseImgUrl + 'painting_18.png'" alt="" class="icon" />
                    <div class="cell">
                        <div class="name">自有生产基地 一站式供应</div>
                        <div class="text">
                            相关随材教具多达百种，通过专业化供应流程，能够持续为加盟校带来多元盈利创收
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div
            class="p_curriclum"
            :style="`background:url(${baseImgUrl}painting_24.png);background-size:contain;`"
        >
            <div class="tilte">
                <img :src="baseImgUrl + 'painting_13.png'" alt="" />
                <h5><img :src="baseImgUrl + 'painting_23.png'" alt="" /></h5>
            </div>
            <div class="content">
                <div class="item red">
                    <div class="item_title">课程体系/课程阶段</div>
                    <div>成长规律 <br /><span> 循序渐进，周而复始 </span></div>
                    <div>学习阶段</div>
                    <div>学习目的</div>
                    <div class="block">学习内容</div>
                </div>
                <div class="item">
                    <div class="item_title">L1启蒙（6-8岁）</div>
                    <div>春<br /><span> 发芽 </span></div>
                    <div>兴趣萌芽期</div>
                    <div>激发兴趣，打下基础</div>
                    <div>国画知识、技法训练 兴趣培养</div>
                </div>
                <div class="item">
                    <div class="item_title">L2提高（8-10岁）</div>
                    <div>夏<br /><span> 生长 </span></div>
                    <div>习惯养成期</div>
                    <div>提高休养，个性创作</div>
                    <div>整体造型、画面结构 细节调整</div>
                </div>
                <div class="item">
                    <div class="item_title">L3进阶（10-12岁）</div>
                    <div>秋<br /><span> 收获 </span></div>
                    <div>成就培养期</div>
                    <div>拓宽视野，强化创作</div>
                    <div>笔墨和颜色进阶<br />艺术鉴赏能力</div>
                </div>
                <div class="item">
                    <div class="item_title">L4高级（12-16岁）</div>
                    <div>冬<br /><span> 沉淀 </span></div>
                    <div>独立创作期</div>
                    <div>独立思想，塑造风格</div>
                    <div>多画种培养<br />个人创作和风格培养</div>
                </div>
            </div>
        </div>

        <div class="p_works">
            <div class="tilte">
                <img :src="baseImgUrl + 'painting_14.png'" alt="" />
            </div>
            <div class="content">
                <div class="company_honor">
                    <div>
                        <swiper ref="mySwiper" :options="swiperOption" class="honnor_swiper">
                            <swiper-slide v-for="item in honors" :key="item.id">
                                <template v-if="item.id">
                                    <img
                                        :src="baseImgUrl + item.url"
                                        :data-url="baseImgUrl + item.url"
                                        alt=""
                                        class="s_img"
                                    />
                                </template>
                            </swiper-slide>
                        </swiper>
                    </div>
                    <div class="swiper-button-next" @click="next">
                        <img :src="baseImg + 'honnor_right.png'" alt="" class="button_img" />
                    </div>
                    <div class="swiper-button-prev" @click="prev">
                        <img :src="baseImg + 'honnor_left.png'" alt="" class="button_img" />
                    </div>
                </div>
            </div>
        </div>

        <div
            class="p_guarantee"
            :style="`background:url(${baseImgUrl}painting_24.png);background-size:contain;`"
        >
            <div class="tilte">
                <img :src="baseImgUrl + 'painting_15.png'" alt="" />
            </div>
            <div class="content">
                <div class="item">
                    <img :src="baseImgUrl + 'painting_25.png'" alt="" class="icon" />
                    <div class="text">成功经验复制 整校标准输出</div>
                </div>
                <div class="item">
                    <img :src="baseImgUrl + 'painting_26.png'" alt="" class="icon" />
                    <div class="text">专业师资培训 教学资格认证</div>
                </div>
                <div class="item">
                    <img :src="baseImgUrl + 'painting_27.png'" alt="" class="icon" />
                    <div class="text">科学课程体系 孩子学习高效</div>
                </div>
                <div class="item">
                    <img :src="baseImgUrl + 'painting_28.png'" alt="" class="icon" />
                    <div class="text">专家全程指导 让您创业无忧</div>
                </div>
                <div class="item">
                    <img :src="baseImgUrl + 'painting_29.png'" alt="" class="icon" />
                    <div class="text">教学步骤规范 老师轻松上手</div>
                </div>
                <div class="item">
                    <img :src="baseImgUrl + 'painting_30.png'" alt="" class="icon" />
                    <div class="text">赠送管理系统 赋能校区运营</div>
                </div>
            </div>
        </div>
        <el-image-viewer v-if="visiableImg" :on-close="previewClose" :url-list="sourceList" />
        <Top :baseInfo="baseInfo" v-show="isTopShow" @openPop="openPop()"/>


        <div class="league_container">
			<div :class="[position === 'fixed' ? 'fixed' : 'relative' , 'league_position']">
				<League :baseInfo="baseInfo" @openPop="openPop()"/>
			</div>
		</div>
        <div ref="footer_container">
			<Footer :baseInfo="baseInfo"/>
		</div>
    </div>
</template>

<script>
import {config} from '../config';
import mixin from '../utils/mixin';
export default {
    name: 'Painting',
    mixins:[mixin],
    data() {
        return {
            baseImg: config.baseImgUrl,
            baseImgUrl : config.baseImgUrl  + 'painting/',

            swiperOption: {
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                },
                slidesPerView: 6,
                spaceBetween: 15,
                loop: true,
                autoplay: false,
                grabCursor: true,
                observer: true,
                observeParents: true,
                on: {
                    click: (swiper, e) => {
                        if (e.target.dataset.url) {
                            this.preview(e.target.dataset.url);
                        }
                    }
                }
            },
            honors: [
                { id: 1, url: 'painting_works_1.png' },
                { id: 2, url: 'painting_works_2.png' },
                { id: 3, url: 'painting_works_3.png' },
                { id: 4, url: 'painting_works_4.png' },
                { id: 5, url: 'painting_works_5.png' },
                { id: 6, url: 'painting_works_6.png' },
                { id: 7, url: 'painting_works_7.png' },
                { id: 8, url: 'painting_works_8.png' },
                { id: 9, url: 'painting_works_9.png' }
            ],
            visiableImg: false,
            sourceList: []
        };
    },
    methods: {
        prev() {
            this.$refs.mySwiper.$swiper.slidePrev();
        },
        next() {
            this.$refs.mySwiper.$swiper.slideNext();
        },
        preview(url) {
            this.visiableImg = true;
            document.body.style.overflow = 'hidden';
            this.sourceList = [url];
        },
        previewClose() {
            document.body.style.overflow = '';
            this.visiableImg = false;
        }
    }
};
</script>

<style lang="scss" scoped>
.painting {
    height: 1000px;
}
.p_banner {
    width: 100%;
    height: 540px;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        cursor: pointer;
    }
}
.p_description {
    height: 500px;
    padding: 100px 0;
    box-sizing: border-box;
    .content {
        max-width: 1200px;
        margin: 0 auto;
        display: flex;
        .item {
            img {
                width: 540px;
                height: 300px;
            }
        }
        .txt {
            font-size: 24px;
            font-weight: 500;
            line-height: 36px;
            color: #333;
            padding-left: 75px;
            text-align: left;
        }
    }
}
.p_line_img {
    height: 150px;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
.p_course {
    height: 740px;
    .tilte {
        margin: 90px 0;
        display: inline-block;
        img {
            width: auto;
        }
        h5 {
            font-size: 24px;
            font-weight: bold;
            line-height: 30px;
            color: #986043;
        }
    }
    .content {
        width: 1200px;
        margin: auto;
        display: flex;
        justify-content: space-between;
        .item {
            width: 46%;
            height: 310px;
            display: flex;
            // justify-content: center;
            border-left: 14px solid #911616;
            position: relative;
            .item_l {
                position: relative;
                text-align: left;
                padding-left: 12px;
                box-sizing: border-box;
                flex: 0 0 80%;
                // width: 460px;
                padding-right: 20%;
                background: #fff;
                h2 {
                    font-size: 28px;
                    font-weight: bold;
                    line-height: 40px;
                    color: #986043;
                    margin: 30px 0 15px;
                }
                p {
                    font-size: 22px;
                    font-weight: 500;
                    line-height: 30px;
                    color: #333;
                }
                .name {
                    position: absolute;
                    left: 12px;
                    bottom: -20px;
                }
            }
            .item_r {
                width: 260px;
                height: 260px;
                position: absolute;
                right: -60px;
                top: 25px;
            }
        }
    }
}
.p_campus {
    height: 770px;
    .tilte {
        margin: 100px 0 90px;
        display: inline-block;
        img {
            width: auto;
        }
    }
    .content {
        display: flex;
        width: 1200px;
        margin: auto;
        .item {
            margin: 0 7px;
            .icon {
                margin-bottom: 25px;
            }
        }
        .cell {
            width: 390px;
            height: 260px;
            background: rgba(255, 255, 255, 0.39);
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            border-radius: 20px;
            padding: 20px 35px;
            box-sizing: border-box;
            .name {
                width: 170px;
                margin: auto;
                font-size: 28px;
                font-weight: bold;
                line-height: 36px;
                color: #333;
                position: relative;
                margin-bottom: 35px;
                &::after {
                    content: '';
                    position: absolute;
                    bottom: -15px;
                    width: 50px;
                    height: 6px;
                    background: rgba(145, 22, 22, 0.39);
                    border-radius: 10px;
                    left: 35%;
                }
            }
            .text {
                text-align: left;
                font-size: 22px;
                font-weight: 500;
                line-height: 33px;
                color: #333;
            }
        }
    }
}
.p_curriclum {
    height: 850px;
    .tilte {
        margin: 97px 0 80px;
        display: inline-block;
        img {
            width: auto;
        }
        h5 {
            margin-top: 10px;
        }
    }
    .content {
        display: flex;
        justify-content: center;
        .item {
            margin: 0 5px;
            div {
                min-height: 60px;
                width: 230px;
                border-radius: 10px;
                font-size: 22px;
                font-weight: bold;
                line-height: 32px;
                background: #fff;
                box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
                margin-bottom: 10px;
                padding: 20px 15px 18px;
                // padding: 0 15px;
                box-sizing: border-box;
                span {
                    font-size: 18px;
                }
            }
            .item_title {
                padding: 0;
                background: #911616;
                height: 60px;
                color: #fff;
                line-height: 60px;
            }
            .block {
                height: 102px;
                line-height: 62px;
            }
        }
        .red {
            color: #911616;
        }
    }
}
.p_works {
    height: 560px;
    .tilte {
        margin: 97px 0 80px;
        display: inline-block;
        img {
            width: auto;
        }
    }
    .content {
        width: 1250px;
        margin: auto;
        .company_honor {
            position: relative;
            .s_img {
                width: 196px;
                height: 196px;
                border-radius: 10px;
                object-fit: cover;
            }
            ::v-deep .el-image__preview {
                object-fit: cover !important;
            }
            .swiper-button-next {
                right: -80px;
            }
            .swiper-button-prev {
                left: -80px;
            }
            .swiper-button-next,
            .swiper-button-prev {
                position: absolute;
                width: 58px;
                height: 58px;
                .button_img {
                    width: 100%;
                    height: 100%;
                }
                &::after {
                    content: '';
                }
            }
        }
    }
}
.p_guarantee {
    height: 700px;
    .tilte {
        margin: 97px 0 80px;
        display: inline-block;
        img {
            width: auto;
        }
    }
    .content {
        width: 1200px;
        margin: auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .item {
            width: 380px;
            height: 140px;
            background: rgba(255, 255, 255, 0.39);
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            border-radius: 20px;
            margin-bottom: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            .text {
                width: 170px;
                font-size: 28px;
                font-weight: bold;
                line-height: 36px;
                color: #333;
                margin-left: 45px;
            }
        }
    }
}
.to_top {
    z-index: 1002;
}
</style>
